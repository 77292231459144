import {
  createTheme,
  CssBaseline,
  Grid,
  IconButton,
  Link,
  makeStyles,
  ThemeProvider,
  Typography,
} from '@material-ui/core'

import { Info } from '../info/Info'
import { Social } from '../social/Social'
import { Statement } from '../statement/Statement'

import qr from '../../img/qr.jpeg'
import bar from '../../img/bar-gray.png'
import React from 'react'

const useStyles = makeStyles({
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
    borderTop: '5px solid #FFFFFF',
    minHeight: '55.95px',
  },
  footer: {
    padding: '30px 0',
  },
  subtitle: {
    fontFamily: 'attribute-mono',
    fontSize: '14px',
    color: '#000000',
    paddingRight: '5px',
  },
  gridContainer: {
    padding: '30px',
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '76vh',
  },
  contact: {
    fontFamily: 'attribute-mono',
    fontSize: '10px',
    color: '#000000',
    paddingBottom: '2px',
  },
  infoContainer: {
    paddingTop: '2px',
    paddingLeft: '1px',
  },
  asideBar: {
    float: 'left',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  qr: {
    borderRadius: '50%',
    height: '60px',
    display: 'flex',
  },
})

export const Home = () => {
  const classes = useStyles()
  const theme = createTheme({
    palette: {
      type: 'light',
      background: {
        default: '#EEEEEE',
      },
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#000000',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <header className={classes.header} />
      <div className={classes.gridContainer}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Grid container justify="center">
            <Grid xs={12}>
              <div>
                <IconButton edge="start" disableRipple={true}>
                  <Link href="/">
                    <img src={qr} alt="qr" className={classes.qr} />
                  </Link>
                </IconButton>
                <Typography className={classes.contact}>
                  <Link
                    href="mailto:me@leonkozlowski.com"
                    target="_blank"
                    color="inherit"
                  >
                    me (at) <strong>leonkozlowski</strong> (dot) com
                  </Link>
                </Typography>
              </div>

              <div>
                <Statement
                  subject={'Data Engineering Lead'}
                  predicate={'Flock'}
                  link={'https://www.flocksafety.com/'}
                />
                {/* <Statement
                  subject={'AI & Analytics'}
                  predicate={'Rutgers'}
                  link={
                    'https://mbs.rutgers.edu/academic-programs/analytics-discovery-informatics-data-sciences-online'
                  }
                /> */}
              </div>
              <br />
              <Typography className={classes.subtitle}>Previous</Typography>
              <div className={classes.infoContainer}>
                <img
                  src={bar}
                  width="4px"
                  height="70px"
                  className={classes.asideBar}
                  alt="asideBar"
                />
                <Info text={'Data Engineer at Bloomberg'} />
                <Info text={'Data Analyst at Bloomberg'} />
                <Info text={'Economics at Rutgers'} />
              </div>
              <br />
              <Typography className={classes.subtitle}>About</Typography>
              <div className={classes.infoContainer}>
                <img
                  src={bar}
                  width="4px"
                  height="45px"
                  className={classes.asideBar}
                  alt="asideBar"
                />
                <Social site="just about" alt="everywhere" handle="leonkozlowski" />
                {/* <Info
                  text={'Things that I'}
                  link={'/uses'}
                  linkText={'use'}
                  secondaryText={'on a daily basis'}
                  target={'_self'}
                /> */}
                <Info
                  text={'Building stuff at'}
                  link={'https://alwaysbuild.ing/'}
                  linkText={'alwaysbuild.ing'}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <footer className={classes.footer} />
    </ThemeProvider>
  )
}
